import React from "react";

export default function Transforming() {
  return (
    <div>
      <section
        className="pricing_3 pricing_4 pt_120 pb_140 xs_pt_80 xs_pb_80 bg-white"
        id="Roadmap"
      >
        <div className="container">
          <h1 className="text-center">Roadmap</h1>
          <section className="design-section">
            <div className="timeline">
              <div className="timeline-empty"></div>

              <div className="timeline-middle">
                <div className="timeline-circle" />
              </div>
              <div className="timeline-component timeline-content">
                <h4>Q4 2024</h4>
                <p className="mt-2">Planning & Launch</p>
                <p>Token Generation Event (TGE)</p>
                <p>Trading Bot Launch</p>
              </div>
              <div className="timeline-component timeline-content">
                <h4>Q1 2025</h4>
                <p className="mt-2">Exchange Listing</p>
                <p>Initial Developments</p>
                <p>Marketing Campaigns</p>
              </div>
              <div className="timeline-middle">
                <div className="timeline-circle" />
              </div>
              <div className="timeline-empty"></div>
              <div className="timeline-empty"></div>
              <div className="timeline-middle">
                <div className="timeline-circle" />
              </div>
              <div className=" timeline-component timeline-content">
                <h4>Q2 2025</h4>
                <p className="mt-2">DEX Liquidity </p>
                <p>Wallet Development</p>
                <p>Blockchain Launch</p>
              </div>
              <div className=" timeline-component timeline-content">
                <h4>Q3 2025</h4>
                <p className="mt-2">Gaming Platform Development</p>
                <p>Global Expansion</p>
                <p>Development Planning For Future</p>
              </div>
              <div className="timeline-middle">
                <div className="timeline-circle" />
              </div>
              <div className="timeline-empty"></div>
              <div className="timeline-empty"></div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}

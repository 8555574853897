import React from "react";

export default function Assistant() {
  return (
    <div>
      <section
        className="generated_area pt_105 xs_pt_65 pb_140 xs_pb_80"
        style={{ background: "url(assets/img/gamingbg.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12 wow fadeInUp">
              <div className="sec_title pb_55 position-relative z-1">
                <h2 className="text-white">
                  Enhancing Decentralized{" "}
                  <span className="span-text"> Gaming Ecosystem </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6 col-md-12">
              <div className="generated_content position-relative">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <div
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    <div className="generated_list_wrapper">
                      <div className="generated_number">
                        <span>01</span>
                      </div>
                      <div className="generated_text">
                        <h3>Various Games</h3>
                        <p>
                          Find numerous decentralized games on BrightBit’s
                          gaming platform.
                        </p>
                      </div>
                      <div className="generated_icon">
                        <a href="#">
                          <img
                            src="assets/images/arrow-angle.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="nav-link "
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    <div className="generated_list_wrapper">
                      <div className="generated_number">
                        <span>02</span>
                      </div>
                      <div className="generated_text">
                        <h3>Explore DeFi Gaming</h3>
                        <p>
                          Explore the decentralized gaming ecosystem with our
                          widely popular application.
                        </p>
                      </div>
                      <div className="generated_icon">
                        <a href="#">
                          <img
                            src="assets/images/arrow-angle.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="nav-link"
                    id="v-pills-messages-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-messages"
                    role="tab"
                    aria-controls="v-pills-messages"
                    aria-selected="false"
                  >
                    <div className="generated_list_wrapper mb_0">
                      <div className="generated_number">
                        <span>03</span>
                      </div>
                      <div className="generated_text">
                        <h3>Play & Win</h3>
                        <p>
                          Start playing games to win huge winning rewards &
                          participation incentives.
                        </p>
                      </div>
                      <div className="generated_icon">
                        <a href="#">
                          <img
                            src="assets/images/arrow-angle.png"
                            alt="Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                  tabIndex={0}
                >
                  <div className="generated_img position-relative">
                    <img
                      src="assets/img/various-vames.png"
                      alt="various-vames"
                      className="img-fluid w-100"
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade "
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                  tabIndex={0}
                >
                  <div className="generated_img position-relative">
                    <img
                      src="assets/img/explore_defi_gaming.png"
                      alt="explore_defi_gaming"
                      className="img-fluid w-100"
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                  tabIndex={0}
                >
                  <div className="generated_img position-relative">
                    <img
                      src="assets/img/play_win.png"
                      alt="play_win"
                      className="img-fluid w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

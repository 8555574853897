import React from "react";

export default function Solutions() {
  return (
    <div>
      <section className="feature_3 feature_4 pb_120 xs_pb_80" id="Products">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 wow fadeInUp">
              <div className="sec_title_3 mb_25 text-black">
                <h2>
                  Our <span className="span-text"> Products & offerings</span>{" "}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6 mt_25 wow fadeInUp">
              <div className="single_feature_3" style={{height:"422px"}}>
                <span>
                  <img src="assets/img/crypto_trading_bot.png" alt="" />
                </span>
                <a className="feature_3_title">Crypto Trading Bot</a>
                <p>
                  We offer various crypto trading bots that automate trades with
                  100% profit accuracy. Once deployed, these bots could fetch
                  hundreds of assets in live markets & execute profitable
                  trades.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mt_25 wow fadeInUp">
              <div className="single_feature_3" style={{height:"422px"}}>
                <span>
                  <img src="assets/img/forex_trading.png" alt="" />
                </span>
                <a className="feature_3_title">Forex Trading</a>
                <p>
                  BrightBit is also involved in Forex trading besides crypto
                  markets. It offers users with highly efficient Forex trading
                  bots to catch quick & instant profiting trades while
                  supporting a wide range of currencies.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mt_25 wow fadeInUp">
              <div className="single_feature_3" style={{height:"422px"}}>
                <span>
                  <img src="assets/img/payment_token.png" alt="" />
                </span>
                <a className="feature_3_title">Payment Token</a>
                <p>
                  BrightBit Token (BBT) is a digital currency that is used as a
                  medium for value transfer. It is used for payments, rewards
                  and purchasing goods and services in our ecosystem.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mt_25 wow fadeInUp">
              <div className="single_feature_3" style={{height:"422px"}}>
                <span>
                  <img src="assets/img/blockchain.png" alt="" />
                </span>
                <a className="feature_3_title">BBT Blockchain</a>
                <p>
                  A blockchain platform to build & deploy scalable decentralized
                  applications (dApps). It enables developers to benefit with
                  cost-efficient infrastructure which also provides high speed
                  transactions.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mt_25 wow fadeInUp">
              <div className="single_feature_3" style={{height:"422px"}}>
                <span>
                  <img src="assets/img/brightbit_nfts.png" alt="" />
                </span>
                <a className="feature_3_title">BrightBit NFTs</a>
                <p>
                  BrightBit NFTs are yield bearing unique digital collectibles
                  that can be leveraged to earn passive income. Acquiring these
                  NFTs help its holders to benefit with exclusive rewards on our
                  platform.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mt_25 wow fadeInUp">
              <div className="single_feature_3" style={{height:"422px"}}>
                <span>
                  <img src="assets/img/gaming_platform.png" alt="" />
                </span>
                <a className="feature_3_title">Gaming Platform</a>
                <p>
                  BrightBit hosts a decentralized gaming platform that offers
                  hundreds of live games which users can play & win crypto
                  rewards. It consists of games like fantasy, sports, betting
                  and gambling.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

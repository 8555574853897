import React from "react";
const today = new Date();
const year = today.getFullYear();

export default function Footer() {
  return (
    <div>
      <footer className="footer footer_3 footer_4 pt_140 xs_pt_80">
        <div className="container">
          <div className="footer_top_area mb_110">
            <div className="row align-items-center">
              <div className="col-xl-6 col-md-5">
                <h4 className="text-white">
                  Read to explore the crypto industry? Join us now & start
                  trading!
                </h4>
              </div>
              <div className="col-xl-6 col-md-7">
                <ul className="d-flex flex-wrap justify-content-end">
                  <li>
                    <a className="btn_primary footer_top_area_btn1" href="#">
                      Join Now
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer_top_wrapper xs_mb_70">
            <div className="row justify-content-center">
              <div className="col-xl-4">
                <div className="footer_item footer_item_3">
                 <div className="d-flex justify-content-center">
                 <a href="#">
                    <img
                      src="assets/img/logo.png"
                      className="img-fluid img-footer" 
                      alt="Footer Logo"
                    />
                  </a>
                 </div>
                  <p className="text-center">
                  Join our community & get latest updates!
                  </p>
                  <ul className="footer_social footer_social_3 d-flex justify-content-center">
                    <li>
                      <a href="#">
                        <i className="fa-brands fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              
            </div>
            <div className="footer_btm mt_55">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="footer_btmItem footer_btmItem_3">
                    <p>© {year} BrightBit. All rights reserved.</p>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="footer_btmItem footer_btmItem_3">
                    <ul className="d-flex gap-3 justify-content-end">
                      <li>
                        <a href="#">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="#">Term of Service</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

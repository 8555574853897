import React from "react";

export default function Allows() {
  return (
    <div>
      <section className="creators_3 creators_4 pb_120 xs_pb_80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5">
              <div className="sec_title_3">
                {/* <h5>Data Engine</h5> */}
                <h2>
                  Hub for crypto & blockchain
                  <span className="span-text"> enthusiasts</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="creators_3_area">
            <div className="row justify-content-between">
              <div className="col-xl-5 col-lg-5 wow fadeInLeft">
                <div className="crators_3_img1">
                  <img
                    src="assets/img/enhancing-trading-experience-section-Image.png"
                    alt="Enhancing Trading Experience"
                    className="img-fluid w-100 e-height"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 my-auto wow fadeInRight">
                <div className="crators_3_text">
                  {/* <h6><span><i className="fal fa-expand-alt" /></span>Social Media</h6> */}
                  <a className="creators_3_title">
                    Enhancing Trading Experience
                  </a>
                  <p>
                    With our latest developments, We seek to enhance trading
                    experience in cryptocurrency and forex markets. Join us &
                    level up your trading game!
                  </p>
                  <a className="btn_primary home_page_3_btn" href="#">
                    Join Now
                    <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="creators_3_area" id="NBBT-TokenFTs">
            <div className="row justify-content-between">
              <div className="col-xl-7 col-lg-7 my-auto wow fadeInLeft">
                <div className="crators_3_text ml_120">
                  <a className="creators_3_title">
                    BBT – Native Currency For Transferring Value
                  </a>

                  <p>
                    BrightBit Token (BBT) is the native token that is used
                    across the whole ecosystem as a means of payments and value
                    transfers.
                  </p>
                  <p className="mb-0">
                    <span style={{ fontWeight: "600" }}> Chain: </span> BNB
                    Chain
                  </p>
                  <p className="mt-0" style={{ overflow: "auto hidden" }}>
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      Contract Address:
                    </span>{" "}
                    <a
                      className="text-black"
                      style={{ borderBottom: "1px solid black" }}
                      target="_blank"
                      href="https://bscscan.com/token/0x164b6f3f35d98214a592e4ba7b92774736a67dbf"
                    >
                      0x164b6f3f35d98214a592e4ba7b92774736a67dbf
                    </a>
                  </p>
                  <a className="btn_primary home_page_3_btn" href="#">
                    Buy BBT
                    <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 wow fadeInRight">
                <div className="crators_3_img1 crators_3_img2">
                  <img
                    src="assets/img/bbt-native-currency-for-transferring-value.png"
                    alt=" BBT – Native Currency For Transferring Value"
                    className="img-fluid w-100 w-buy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="creators_3_area" id="NFTs">
            <div className="row justify-content-between">
              <div className="col-xl-5 col-lg-5 wow fadeInLeft">
                <div className="crators_3_img1">
                  <img
                    src="assets/img/global-grow-nfts.png"
                    alt=" Global Grow NFTs"
                    className="img-fluid w-100 h-511"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 my-auto wow fadeInRight">
                <div className="crators_3_text">
                  {/* <h6><span><i className="fal fa-expand-alt" /></span>Social Media</h6> */}
                  <a className="creators_3_title">BrightBit NFTs</a>
                  <p>
                    The unique NFT collection enables users to benefit with
                    additional rewards. Grab some NFTs and start bearing yields
                    now!{" "}
                  </p>
                  <a className="btn_primary home_page_3_btn" href="#">
                    NFTs
                    <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

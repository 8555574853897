import React from "react";
import Marquee from "react-fast-marquee";

export default function Marqueee() {
  return (
    <div>
      <section className="branding_3 branding_4 pt_80 pb_120 xs_pb_80">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 wow fadeInUp">
              <div className="branding_3_area">
                <h5 className="mb-5">
                  {" "}
                  <span className="span-text">20+ Ecosystem Partners</span>
                </h5>
                <Marquee>
                  <div>
                    <a className="c-pointer mx-4 ">
                      <img
                        src="assets/img/bnbchain.png"
                        alt="BNB Chain"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/brightbit.png"
                        alt="BrightBit Token"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/bscscan.png"
                        alt="BSCscan"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/coinpeprika.png"
                        alt="Coinpaprika"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/crypto_potato.png"
                        alt="CryptoPotato"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/drops_tab.png"
                        alt="DropsTab"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/yahoo.png"
                        alt="YahooFinance"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4 ">
                      <img
                        src="assets/img/bnbchain.png"
                        alt="BNB Chain"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/brightbit.png"
                        alt="BrightBit Token"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/bscscan.png"
                        alt="BSCscan"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/coinpeprika.png"
                        alt="Coinpaprika"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/crypto_potato.png"
                        alt="CryptoPotato"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/drops_tab.png"
                        alt="DropsTab"
                        className="img-fluid "
                      />
                    </a>
                    <a className="c-pointer mx-4">
                      <img
                        src="assets/img/yahoo.png"
                        alt="YahooFinance"
                        className="img-fluid "
                      />
                    </a>
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

import React from "react";

export default function Hero() {
  return (
    <div>
      <section className="banner_3_area banner_4 pt_80">
        <div className=" banner_3_bg">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-6 col-lg-6 my-auto wow fadeInLeft">
                <div className="banner_3_text sec_title_3">
                  {/* <h5>Welcome CIRCUITRY</h5> */}
                  <h2>
                    Connect to the wide
                    <span className="span-text"> crypto spectrum</span>
                  </h2>
                  <p className="pt-3 m-0 fs-5">
                    Join BrightBit & connect with the team of individuals
                    expertised in blockchain, crypto & finance!
                  </p>
                  <a class="btn_primary home_page_3_btn mt-3 py-3" href="https://brightbits-organization.gitbook.io/brightbit" target="_blank" style={{borderRadius:"20px 0px 30px 0px"}}>Whitepaper<i class="fal fa-angle-right" aria-hidden="true"></i></a>
                  {/* <form className="banner_3_singup">
                    <input type="text" placeholder="Enter your email" />
                    <a
                      className="btn_primary home_page_3_btn banner_3_singup_btn"
                      href="#"
                    >
                      Sign Up Free
                      <i className="fal fa-long-arrow-right" />
                    </a>
                    <span>
                      <img
                        src="assets/images/mail_icon.png"
                        alt="icon"
                        className="img-fluid w-100"
                      />
                    </span>
                  </form> */}
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 wow fadeInRight">
                <div className="banner_3_img">
                  <div className="banner_3_img_1">
                    <img
                      src="assets/img/hero_section.svg"
                      alt="img"
                      className="img-fluid w-100"
                    />
                  </div>
                  {/* <div className="banner_3_img_2">
                    <img
                      src="assets/images/banner_4_img_2.jpg"
                      alt="img"
                      className="img-fluid w-100"
                    />
                  </div>
                  <div className="banner_3_img_3">
                    <img
                      src="assets/images/banner_4_img_3.jpg"
                      alt="img"
                      className="img-fluid w-100"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

import React from "react";
import Header from "../Coman/Header";
import Hero from "./Hero";
import Solutions from "./Solutions";
import Allows from "./Allows";
import Transforming from "./Transforming";
import Rather from "./Rather";
import Assistant from "./Assistant";
import Footer from "./Footer";
import Marqueee from "./Marqueee";

export default function Index() {
  return (
    <div>
      <Header />
      <Hero />
      <Marqueee />
      <Solutions />
      <Allows />
      <Assistant />
      <Transforming />
      <Rather />
      <Footer />
    </div>
  );
}

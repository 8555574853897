import React from "react";
import { HashLink } from "react-router-hash-link";

export default function Header() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg main_menu main_menu_3 main_manu_4">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="assets/img/logo.png"
              alt="logo"
              height="200px"
              className="img-fluid"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="far fa-bars menu_icon_bar" />
            <i className="far fa-times close_icon_close" />
          </button>
          <div
            className="collapse navbar-collapse justify-content-around"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/#Products">
                  Products{" "}
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/#NBBT-TokenFTs">
                  BBT Token
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/#NFTs">
                  NFTs
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/#Roadmap">
                  Roadmap
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/#About">
                  About
                </HashLink>
              </li>
            </ul>
            <ul className=" menu_right menu_3_right d-flex align-items-center">
              {/* <li>
                <a className="user_icon" href="#">
                  <i className="far fa-user" />
                  Login
                </a>
              </li> */}
              <li>
                <a className="btn_primary home_page_3_btn" href="#">
                  Start Now
                  <i className="fal fa-angle-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
